

// make sure it overrides material2 styles
a,
button {
  &.btn-w-xs { min-width: 80px; }
  &.btn-w-sm { min-width: 100px; }
  &.btn-w-md { min-width: 135px; }
  &.btn-w-lg { min-width: 160px; }

  &.btn-round { border-radius: 2em; }
}

.btn-cta.ant-btn, // antd
.btn-cta {
  height: auto;
  padding: .6em 3.5em;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: 2;
  font-size: $font-size-sm;
  border-radius: 36px !important;
}
.ant-btn:active, .ant-btn.active{
  border-color: white !important;
}
.ant-btn-cancel{
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-cancel:hover{
  color: #fff !important;
  background-color: #464a58 !important;
  border-color: #464a58 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-secondary{
  color: #fff !important;
  background-color: #868e96 !important;
  border-color: #868e96 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.ant-btn-secondary:hover{
  color: #fff !important;
  background-color: #464a58 !important;
  border-color: #464a58 !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{
  box-shadow: none !important;
}

.btn-secondary:focus, .btn-secondary.focus{
  box-shadow: none !important;
}
// 
a.icon-btn-v2,
.icon-btn-v2 {
  color: $white;
  height: auto;
  padding: .5em 2.5em;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
  }

  i {
    margin-right: .5rem; 
  }

  .btn-text {
    letter-spacing: .5px;
    text-transform: uppercase;
    font-size: $font-size-sm;
  }
}
@media screen and (max-width:420px){
  .ant-btn {
      padding: 0 11px;
      font-size: 12px;
  }
  }