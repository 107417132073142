.feature-callout {
  position: relative;
  @include clearfix;

  &.feature-content-left {
    .callout-feature-content {
      @media (min-width: map-get($grid-breakpoints, md)) { padding-right: 2em; }
      @media (min-width: map-get($grid-breakpoints, xl)) { padding-right: 3em; }
    }
  }
  &.feature-content-right {
    .callout-feature-content {
      @media (min-width: map-get($grid-breakpoints, md)) { padding-left: 2em; }
      @media (min-width: map-get($grid-breakpoints, xl)) { padding-left: 3em; }
    }
  }

  .offset-md-6 {
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-left: 50%;
    }
  }
}

.feature-callout-image-pull {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  @media (min-width: map-get($grid-breakpoints, md)) {
    position:absolute;
    top: 0;
    bottom: 0
  }
}

.callout-feature-content {
  padding: 3em 0;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-top: 6em;
    padding-bottom: 6em;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-top: 8em;
    padding-bottom: 8em;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    padding-top: 12em;
    padding-bottom: 12em;
  }

  h2 {
    font-size: 24px;
    margin-top: 0;
    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: $h2-font-size;
      font-weight: $font-weight-light;
      line-height: $line-height-sm;
      margin: 0;
    }
  }
  div {
    line-height: $line-height-base;
    hyphens: none;
    margin: 1.625rem 0;
    p:last-child {
      margin-bottom: 0
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
      line-height: $line-height-lg;
    }
  }
}
