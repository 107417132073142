// Bootstrap grid is used for screen >= map-get($grid-breakpoints, lg)
.form-card {
  background: $white;
  border-radius: $border-radius;

  img {
    width: 100%;
    max-width: 100%;
  }

  .form-card__img {
    width: 100%;
    min-height: 300px;
    // border-radius: $border-radius $border-radius 0 0;
    padding: 0;
    background-size: cover;
    background-position: 50% 50%;
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    // .form-card__img--left {
    //   border-radius: $border-radius 0 0 $border-radius;
    // }
    
    // .form-card__img--right {
    //   border-radius: 0 $border-radius $border-radius 0;
    // }
  }
}
