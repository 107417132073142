$theme_option_item_height: 60px;

// Colors options
// for logo (there're 2, one for sidenav-header, one for header logo, here only for sidenav-header), top header, sidenav
.color-option-check {
  position: relative;
  display: block;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  .dis-none{
    display: none !important;
  }
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
      text-align: center;
      line-height: 30px;  
      color: #fff;  
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //  
  .color-option-item {
    overflow: hidden;
    display: block;
    box-shadow: 0 0 2px rgba(0,0,0,.1);
    margin-bottom: 15px;

    > span {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
    }

    .item-header {
      height: 10px;
    }
  }
}

// to make it more distinguishable from white color
.color-option-check {
  .bg-page {
    background-color: #ececec !important; // vs #fff, #f1f1f1
  }
}



// Themes options
.theme-options {
  > div {
    padding: 0;
  }
}
.theme-option-check {
  position: relative;
  display: block;
  margin: 0;
  font-weight: normal;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0,0,0,.2);
      text-align: center;
      line-height: $theme_option_item_height;  
      color: #fff;
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //  
  .theme-option-item {
    overflow: hidden;
    display: block;

    > span {
      display: block;
      text-align: center;
      height: $theme_option_item_height;
      line-height: $theme_option_item_height;
      text-transform: uppercase;
    }
  }
}
// .app-header-inner.bg-info {
//   background: #fff !important;
// }
// .app-header .bg-dark a, .app-header .bg-primary a, .app-header .bg-success a, .app-header .bg-info a, .app-header .bg-danger a {
//   color: #2A58AE;
// }
// .app-header .bg-dark, .app-header .bg-primary, .app-header .bg-success, .app-header .bg-info, .app-header .bg-danger {
//   color: #2A58AE;
// }
// .sidenav-bg-light {
//   background-color: #2A58AE;
// }
// .ant-menu{
//   background: transparent;
// }
// .ant-menu-item > a {
//   color: rgb(255, 255, 255);
// }
// .ant-menu-item > a:hover{
//   color: #cacaca;
// }.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
//   border-right: 3px solid #ffffff;
// }
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: #1246a7;
// }
// .sidenav-bg-light .sidenav-footer a {
//   color: rgb(245, 245, 245);
// }
// 


// .ant-card.enrolled, .ant-card.not-enrolled{
//   cursor: pointer;
// }
// .ant-card.enrolled .ant-card-head .ant-card-head-title, .ant-card.not-enrolled .ant-card-head .ant-card-head-title{
//   padding: 0px;
// }
// .ant-card.enrolled .ant-card-head {
//   background: #2A58AE;
//   color: white;
//   text-transform: capitalize;
// }
// .ant-card.not-enrolled .ant-card-head {
//   background: #5cd5cd;
//   color: white;
//   text-transform: capitalize;
// }
// .ant-card{
//   border-radius: 4px 4px 0px 0px;
// }
.app-sidenav.ant-layout-sider-collapsed .sidenav-header:before {
  width: 5px;
  height: 60px;
  content: "";
  background: #2a58ae;
  position: absolute;
  right: 0px;
}
.timersteprighall{
  position: fixed;
  right: 21px;
  border-left: 5px solid #2aaeae !important;
  min-width: 250px;
  max-width: max-content;
  width: auto;
  top: 62px;
  font-size: 13px;
  line-height: 22px;
  color: #000000d9;
  font-weight: bolder;
  border: 1px solid #78f8fa;
  background-color: #e6fffe;
  text-align: center;
  border-radius: 2px 6px 6px 2px;
  padding: 4px 0px;
}
.fontblosa{
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
    color: #000000d9;
}
.boxblack{
  background-color: #4c4c4c;
  border-radius: 4px;
  padding: 3px;
  color: #FFF;
  font-size: 13px;
  margin: 0px 3px 0px 0px;
  min-width: 25px;
  display: inline-block;
  font-weight: 500;
}
.actionends{
  margin-bottom: 10px;
}
.actionends .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table thead tr th:last-child{
  text-align: right;
  width: 180px;
  }
  .actionends .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body table tbody tr td:last-child{
    text-align: right;
    width: 180px;
    }
.alter-tble {
  border-bottom: 1px solid #c9c9c9;
}
.tablehr{
  background: #2a58ae;
  padding: 5px;
  color: #FFF;
}
.bordertable{
  border: 1px solid #c9c9c9;
  margin: 10px auto;
}
.app-sidenav.ant-layout-sider-collapsed .sidenav-header {
    padding-left: 17.5px;
    overflow: hidden;
  }
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d4e3ff;
 }
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
     border-right: 3px solid #2A58AE;
 }
.unroll-hover, .enroll-hover {
  position: absolute;
  width: 100%;
  height: 59px;
  background: rgba(42, 88, 174, 0.3);
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: flex-end;
    align-items: center;
    display: none;
    padding-right: 10px;
}
.ant-card.enrolled:hover .unroll-hover{
display:flex;
}
.ant-card.not-enrolled:hover .enroll-hover{
  display:flex;
  }
  .success-btn{
    background-color: #52c41a !important;
    color: #fff !important;
    min-width: 100px;
  }
  .warning-btn{
    background-color: #faad14 !important;
    color: #fff !important;
    min-width: 100px;

  }
  .info-btn{
    background-color: #1890ff !important;
    color: #fff !important;
    min-width: 100px;

    
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2a58ae  !important;
    border-color: #2a58ae  !important;
    border-radius: 36px !important;
  }
  .tox-tinymce:hover{
    box-shadow: 0 0 0 2px #ffffff33 !important;
  }
  .ant-btn-primary{
    background-color: #2a58ae  !important;
    border-color: #2a58ae  !important;
    border-radius:36px !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-radius: 36px !important;
  }
.ant-checkbox span{
  border-radius: 36px !important;
}


  // i.anticon.anticon-check{
  //   color: #2A58AE !important;
  //   border-color: #2A58AE !important;
  // }
  .ant-radio-checked::after{
    border-color: #2A58AE !important;
  }
  .demo-nav {
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    // background: #f8f8f8;
  }
  .artfiletext {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 19px;
    padding-top: 5px;
    color: #2A58AE;
  }
  .heightficer{
    height: auto !important;
    padding-right: 190px;
    position: relative;
    display: inline-block;
    padding-top: 25px;
  }

  .psortel{
    position: absolute;
    top: -43px;
    right: 0px;
}
.position-relative{
    position: relative;
}
.ant-breadcrumb a:hover {
  color: #2a58ae !important;
}

  .demo-nav a {
    line-height: 30px;
    padding: 0 8px 0px 0px;
  }
  .app-list {
    margin-top: 16px;
  }
  .demo {
    // margin: 16px;
    margin: 0px;

  }
  .dashtext {
    color: #131D40;
    font-size: 22px;
    line-height:22px;
    padding: 0px 0px 22px 0px;
}
.ant-table-thead > tr > th{
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #2a58ae  !important;
  color: #FFF !important;
  padding: 2px 10px !important;
}
// .ant-table-tbody > tr > td {
//   vertical-align: top !important;
// }
.ant-table-wrapper{
  border-radius: 15px 15px 0px 0px !important;
}
p{
  font-size: 15px;
    line-height: 22px;
}
.ant-table-bordered .ant-table-thead > tr > th{
  border-right: none !important;
}
.ant-table table, .ant-table-placeholder{
  border: 1px solid #e8e8e8 !important;
}
.ant-table-bordered .ant-table-body > table{
  border: 1px solid #e8e8e8 !important;
}
.ant-notification-notice-description{
  font-size: 15px !important;
  font-weight: 600;
}
.ant-notification-topLeft{
  left: 35% !important;
}
.notification-main{
  cursor: pointer;
  background: #f7f7f7;
  overflow: hidden;
  line-height: 1.5;
  position: relative;
  left: 0% !important;
  top: 0px;
  z-index: 1000;
  display: block;
  bottom: auto;
}
.popupnoti{
  position: fixed;
  z-index: 1001;
  width: 512px;
  left: 34% !important;
  top: 64px;
  padding: 5px;
  border-radius: 6px;
}
.notifimm{
  background-color: #f7f7f7;
  box-shadow: 0px 0px 8px #bfbfbf !important;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 12px;
}
.notif-survey i{
  color: black !important;
  margin-right: 5px;
  font-size: 23px;
  line-height: 17px;
}
.notif-survey{
  width: 100%;
  padding-bottom: 1px;
  color: #2b58ae;
  font-size: 17px;
 padding: 2px 12px 4px 12px !important;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  background-color: #f7f7f7;
}
.survey-description{
  font-size: 16px !important;
  font-weight: 600;
  color: #595959;
  line-height: 20px;
  padding: 8px 12px 7px 12px !important;
  background-color: #FFF;
    border-radius: 6px;
}
.ant-notification-notice-message{
  border-bottom: 1px solid #ececec;
  width: 100%;
  padding-bottom: 1px;
  margin-bottom: 4px !important;
  color: #2b58ae;
}
.ant-notification-notice{
  padding: 3px 12px 7px 12px !important;
  margin-bottom: 6px !important;
  cursor: pointer;
  box-shadow: 0px 0px 6px #bfbfbf !important;
}
@media screen and (max-width: 834px){

  .dashtext {
    font-size: 18px !important;
    padding: 10px 0px 5px 0px !important;
  }
}