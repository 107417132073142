// load on blockquote page only
// @import url('https://fonts.googleapis.com/css?family=Merriweather:300i,400i');

.blockquote-v1 {
  font-family: 'Merriweather', serif;
  font-size: 1.625rem;
  line-height: 1.5em;
  font-weight: 300;
  font-style: italic;
  margin: 0;

  .bq-author {
    display: block;
    margin-top: 1em;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-family: Nunito Sans,sans-serif;
    font-size: $font-size-base;
  }
}