
// fix 3.10.x
.ant-layout-content {
  min-height: 100%;
  position: relative;
  padding-top:58px;
  padding-bottom: 40px;
}
.inp-file{
  border: 1px solid #c7c7c7;
    border-radius: 3px;
    width: 100%;
}