.app-layout {
  &.ant-layout {
    // make sidenav full height
    align-items: stretch; 
    min-height: 100vh;
  }
}
.app-layout.ant-layout {
  // align-items: stretch;
  min-height: 100vh!important;
}