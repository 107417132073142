.app-header {
  padding: 0;
  border: 0; // no border, otherwise other elements' height can only be ($l_header_height -1)

  &.ant-layout-header {
    height: $l_header_height;
    line-height: $l_header_height;
    box-shadow: 0px 3px 5px #dfdfdf94;
    z-index: 555;
  }

  .app-header-inner {
    height: $l_header_height;
  }
}
.ant-steps-item-finish .ant-steps-item-icon, .ant-input:hover, .ant-pagination-item-active, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #2a58ae !important;
}
.posbgecolor video{
  width: 556px !important;
  height: 314px;
}
.posbgecolor iframe{
width: 556px !important;
height: 314px;
}
.img-flash .ant-card .ant-card-body p p img{
  width: 100%;
  height: 90px;
}
.bgcolorblack .ant-card .ant-card-head{
  background-color: #464a58 !important;
}
.pagdes{
  padding: 14px;
  font-size: 15px;
  line-height: 24px;
  color: #787878;
}
.headDesc{
  padding: 14px;
  position: relative;
  top:2%;
}
.gallery48 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
  overflow: hidden;
}

.contenthead48 {
  width: 100%;
  height: auto;
  transition: .5s;
  margin-bottom: 10px;
  opacity: .8;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.img-flash {
  display: flex;
    width: 100%;
    min-height:300px;
    max-height: 300px;
    margin-bottom: 20px;
}
.img-flash .ant-card{
  width: 100%;
  background-color: #f5f5f5;
 }
.img-flash .ant-card .ant-card-body{
  display: block;
    padding: 0px !important;
}
.img-flash .ant-card:hover{
  background-color: #f9f9f9;
  box-shadow: 0px 0px 8px #f3f3f3;
}
.frontspage{
  font-weight: normal;
  line-height: 18px;
  font-size: 15px;
  padding:5px 10px;
  overflow: hidden;
  height: 120px;
  margin-bottom: 20px;

}
.frontspage table{
  width: inherit !important;
overflow: hidden;
height: inherit !important;
padding: 10px;
display: block;
}
.frontspage h1{
  font-weight: normal  !important;
  line-height: 18px !important;
  text-align: left !important;
  font-size: 15px !important;
}
.frontspage h1 span{
  font-weight: normal  !important;
  line-height: 18px !important;
  font-size: 15px !important;
  text-align: left !important;
}
.frontspage span{
  font-weight: normal  !important;
  line-height: 18px !important;
  font-size: 15px !important;
  text-align: left !important;
}
.backspage h1{
  font-weight: normal  !important;
  line-height: 18px !important;
  font-size: 15px !important;
  text-align: left !important;
}
.backspage h1 span{
  font-weight: normal  !important;
  line-height: 18px !important;
  font-size: 15px !important;
  text-align: left !important;
}
.backspage span{
  font-weight: normal  !important;
  line-height: 18px !important;
  font-size: 15px !important;
  text-align: left !important;
}
.frontspage p{
  display: list-item;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 120px;
  padding: 10px;
}
.backspage p{
  display: list-item;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px;
  min-width: 120px;
}
.frontspage img{
  width: 100%;
}
.frontspage table tr{
  vertical-align: top;
}
.frontspage table tr td{
  vertical-align: top;
  max-width: 120px;
  min-width: 120px;
}
.frontspage table tr td img{
  width: 100%;
  display: inline-block;
}
.backspage table tr{
  vertical-align: top;
}
p{
  font-size: 15px;
    line-height: 22px;
}
.backspage table tr td{
  vertical-align: top;
  max-width: 120px;
  min-width: 120px;
}
.backspage table tr td img{
  width: 100%;
  display: inline-block;
}
.backspage img{
  width: 100%;
}
.backspage table{
  width: inherit !important;
  display: block;
overflow: hidden;
padding: 10px;
height: inherit !important;
}
.frontspage:hover{
  background-color: #ececec;
    color: #000;
}
.backspage:hover{
  background-color: #ececec;
    color: #000;
}
.backspage{
  font-weight: normal;
  line-height: 18px;
  font-size: 15px;
  padding: 5px 10px;
  height: 120px;
  overflow: hidden;
  margin-bottom: 20px;

}
.cursor-pointer{
  color: pointer;
}
.img-flash .ant-card .ant-card-body  p img{
  width: 100%;
  height: 90px;
}
.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{
  border-color: none !important;
  box-shadow:none !important;
}
.ant-input-number-input:focus{
  border-color:none !important;
  box-shadow:none !important;
}
.ant-input:focus{
  box-shadow: none !important;
  border-color: #2a58ae !important;
}
html {
  --antd-wave-shadow-color: none !important;
}
.minheigh200{
  min-height: 550px;
}
.ant-input-number:hover{
  border-color: #2a58ae !important;
  box-shadow:none !important;
}
.signeinasblk{
  display: none;
}
.posrtiop{
  position:absolute;
  right: 6px;
  top: 3px;
  font-size: 20px;
  color: #2957ad;
}
.img-flash:hover .ant-card .ant-card-body .posrtiop {
  visibility:visible;
}
.posrtiop{
  visibility: hidden;
}
.posrtiop i{
  margin: 0px 2px;
}
.mrdpl {
  display: inline-block;
  min-width: 200px;
}
//Advanced mode
.tox-notifications-container {
  display: none !important;
}
.tox-statusbar__branding {
  display: none !important;
}
.tox-statusbar__path {
  display: none !important;
}
.tox-tinymce:hover {
  outline: 0;

  border-color: #2a58ae !important;
}
.tox-tinymce:focus {
  outline: 0;

  border-color: #2a58ae !important;
}

.tox-focusediter:focus-within {
  outline: 0;

  border-color: #2a58ae !important;
}
.focusediter:hover {
  outline: 0;

  border-color: #2a58ae !important;
}
.focusediter:focus {
  outline: 0;

  border-color: #2a58ae !important;
}

.focusediter:focus-within {
  outline: 0;

  border-color: #2a58ae !important;
}
//Advanced mode end
.ant-select-open .ant-select-selection{
  border-color: #2a58ae !important;
}
// .ant-select-selection, .ant-input-number-input{
//   border: 1px solid #2a58ae;
// }
.ant-select-selection:hover{
  border-color: #2a58ae !important;
}
.ant-btn-success{
  color: #FFF ;
  background-color: #2dc26b !important;
  border-color: #2dc26b !important;
}
.app-header-inner {
  display: flex;
  justify-content: space-between;
  transition: $l_transition_antd;

  .logo-img {
    float: left; // fix its position affect other list items
    margin: 18px 15px 0; // ($l_header_height - height) / 2 = (60 - 24)/2
  }
img{
  max-width: 100%;
}
  .ant-divider-vertical {
    background: rgba(222,222,222, .5);
    top: -2.5px;
  }

  .header-right {
    padding-right: 5px;
  }

  .header-icon {
    display: inline-block;
    height: $l_header_height;
    line-height: $l_header_height;
    padding: 0 14px;
    @media only screen and (min-width: $l_screen_md_min) {
      padding: 0 18px;
    }
  }

  .ant-menu-horizontal {
    line-height: ($l_header_height - 2); // 2px border
    border-color: transparent;
  }

  a.list-inline-item {
    &:hover {
      background-color: rgba(0,0,0,.05);
    }
  }
  .list-inline-item {
    padding: 0 .875rem;
    margin: 0;
    transition: all .3s;

    .ant-badge {
      .ant-badge-count {
        font-size: 10px;
        line-height: 20px;
        font-weight: $font-weight-light;
        padding: 0 4px;
      }
    }
    .ant-popover-inner{
      box-shadow: 0 2px 8px #0000006b !important;
    }
    .anticon {
      display: inline-block;
    }
    // apply to .list-inline-item icon only
    .list-icon {
      font-size: 1rem;
      line-height: $l_header_height;;
      padding: 0 4px;
    }
    .list-icon-v2 { // with no line-height, for alignment issue
      font-size: 1rem;
      padding: 0 4px;
    }
    .list-notification-icon {
      font-size: 1rem;
      padding: 4px;
    }

    .avatar-text {
      margin-left: .5rem;
    }
  }
}

.app-header-dropdown {
  .ant-dropdown-menu-item {
    i {
      margin-right: .5rem;
    }
  }
}

//
.app-header-popover {
  max-width: 300px;
  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    max-width: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
.app-header-notifications {

  .ant-tabs-nav .ant-tabs-tab {
    padding: 1rem;
  }

  .ant-tabs-bar {
    margin-bottom: .25rem;
  }

  .ant-list-item {
    padding: .75rem 1.5rem;
    transition: background-color .2s;
    &:hover {
      background-color: rgba($info, .08);
      cursor: pointer;
    }
  }

  .ant-list-footer {
    padding: 0;

    a {
      display: block;
      padding: .75rem;
      text-align: center;
      color: $body-color2;
      transition: all .2s;

      &:hover {
        color: $body-color;
        background-color: rgba($gray-900, .03);
      }
    }
  }
}
table tbody tr:nth-child(even) {background-color: #f2f2f2;}
.ant-input:focus{
  box-shadow: none !important;
  border-color: #2a58ae !important;
}
// .ant-layout main .container-fluid{
//   min-height: 750px;
// }
html {
  --antd-wave-shadow-color: none !important;
}
.posficesidenav{
  position: fixed;
  width: 986px;
  top: 7px;
  left: 15%;
  z-index: 599;
}
.delsind{
  font-size: 16px;
  cursor: pointer;
color: #3a3a3a;
line-height: 17px;
padding: 0px 5px;
}
.eyesind{
  font-size: 16px;
    color: #2a58ae;
    cursor: pointer;
    line-height: 17px;
    padding: 0px 5px;
}
.ant-menu-item-selected{
  background-color: #f0f3ff !important;
  border-radius: 2px 2px 0px 0px;
  border-bottom: 2px solid #2a58ae;
}
.model-pad-0 .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body{
  padding: 5px 10px !important;
}
.search-box {
  max-width: 180px;
  height: $l_header_height;
  line-height: 24px;

  &.list-inline-item {
    padding: 10px 0;
  }

  // based on Bootstarp .input-group
  .search-box-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // align-items: stretch;
  }

  input {
    position: relative;
    flex: 1 1 auto;
    padding: 7px 10px 5px;
    width: 1%;
    margin-bottom: 0;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
  }

  .search-box-icon {
    // display: flex;
    // align-items: center;
    padding: 8px 0 4px 10px;

    .anticon {
      padding: 0;
    }
  }

  //
  .input-bar {
    position: absolute;
    display: block;
    width: 90%;
    bottom: 0;
    left: 5%;
  }
  .input-bar:before,
  .input-bar:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    background: $body-color;
    transition: all .25s;
  }
  .input-bar:before {
    left: 50%;
  }
  .input-bar:after {
    right: 50%;
  }
  input {
    &:focus ~ .input-bar:before,
    &:focus ~ .input-bar:after {
      width: 50%;
    }
  }
}
// Hack for vetical centering when .search-box is place on the right
.seach-box-right {
  .search-box-icon {
    position: relative;
    top: 2px;
  }
  input {
    padding: 9px 10px 3px;
  }
}

.colordrakblack{
  color: #464A58 !important;
}
.roundcir{
  width: 36px !important;
  height: 36px !important;
  background-color: #464A58 !important;
  border-radius: 30px !important;
  color: #FFF !important;
  font-size: 16px !important;
  padding: 8px;
}
.menutoggle{

  display: none !important;
}
.bgcolorcli{
  background-color: #464A58 !important;
}
.bgliera{
  background-image: linear-gradient(to bottom, #65656500, #f3f3f3b8);
}
.textforbgcle-bs {
  width: 100%;
  border-radius: 0.375rem 0.375rem 0px 0px;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0px 2px 5px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  color: #2a2a2a;
}
.anchoer{
  color: #005eb5;
  text-decoration: underline;
}
.mainpaymenthead {
  color: #24a976 !important;
  font-size: 23px;
  line-height: 20px;
  text-align: center;
  padding-left: 0px;
  padding-top: 10px;
  font-weight: 700;
  text-decoration: underline;

}
.questiontext p{
  padding: 5px;
}
.videoflash-sm1{
  background-color: #1890ff;
    padding: 5px;
    border-radius: 36px;
    color: #FFF;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 17px;
    display: inline-block;
    position: relative;
    left: 4px;
}
.blueslne{
  color: #4b708a!important;
  font-size: 17px;
  line-height: 20px;
  position: relative;
  top: 5px;
}
.errofeild .anticon-close-circle{
  position:absolute !important;
}
.modpopwidth .ant-modal-content .ant-modal-body{
  padding: 12px !important;
}
.width-100per{
  width: 100%;
}
.pinkclr {
  color: #4b708a !important;
}
.bgcolorcli ul{
  color:#FFF !important;
  background: #FFF !important;
  display: flex;
}
.ant-menu-item-selected .menutext .whitetext { font-weight: 700; color: #000 !important;}
.bgcolorcli ul li{
  padding:0px !important;
  height: auto !important;
  width: 20% !important;
}
.whitetext{
  color: #464a58  !important;
  white-space: break-spaces !important;
  padding: 7px 0px;

}

.ant-layout main{
  min-height: 600px;
}
.appsidenew{
   -webkit-flex:100% !important;
    flex: 100% !important;
    max-width: initial !important;
    min-width: auto!important;
    width: 100% !important;
}
.morehead a{  color: #555555 !important; }
.morehead{
  color: #555555 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #FFF;
  margin: 6px 0px 0px auto;
  width: 166px;
  border-radius: 5px;
  box-shadow: 0 0px 5px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
  text-align: center !important;
}
.form-v1-container .lead {
  margin-bottom: 0px !important;
  font-size: 14px;
  margin-top: 6px;
}
.form-v1-container h2 + .form-v1, .form-v1-container .lead + .form-v1 {
  margin-top: 20px !important;
}
.moresub{
  width: 130px;
  clear: both;
  display: inline-block;
  position: relative;
    bottom: 3px;
}
.app-sidenav .sidenav-header {
  padding: 0 0 0 0px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #696969;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}
.imgicr{
  width: 25px;
  margin: 0px 9px 0px auto;
  display: none;
}
.imgicr img{
  width: 100%;
}
.menutext{
  font-size: 12px !important;
    font-weight: 600 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-family: 'Nunito Sans', sans-serif !important;
    padding: 0px 2px;
}
.eyesee{      padding: 8px 0px 8px 7px;
  display: inline-block;
  background-color: #464a58;
  width: 32px;
  color: #FFF;
  font-size: 18px;
  border-radius: 6px 0px 0px 6px;
  text-align: left;
}
.menuitem{
  margin: 0px !important;
  // border-bottom:1px solid #707070;
}
.bordernone{
  border: none !important;
}
.app-header-inner{
  background-color: #FFF !important;
}
.sidenav-header{
  background-color: #FFF !important;
  position: fixed;
  z-index: 600;
}
.logo-mobile{
  height: 60px;
  padding: 0 0 0 3px;
  line-height: 50px;
}
.sptext{
  display: block;
  padding: 0px !important;
  line-height: 17px;
}
.mrgt10{
  margin-top: 10px;
}

.mrgb10{
  margin-bottom: 10px;
}
.ant-breadcrumb-separator {
  margin: 0px !important;
}
.mrgtb{
  margin: 0px 0px 15px 0px !important;
}
.boxwhite{
  background-color: #ffffff;
    box-shadow: 0px 0px 10px #dadada40;
    border: none !important;
    border-radius: 0px !important;
}
.questtitle{
  font-weight: 700;
  font-size: 15px !important;
  line-height: 20px !important;
}
.answetext{
  color: #21AC56;
  font-size: 16px;
  font-weight: 500;
}
.output_code{
  font-size: 13px !important;
}
.answerbox{
  border: 2px solid #21AC56;
  font-size: 13px;
  width:78px;
  text-align: center;
  border-radius: 6px;
  margin: 3px 0px;

}
.accself{
  margin: 0px auto 0px  0px;
}
.mycls{
  text-transform: uppercase;
  font-weight: 600;

}
.ant-btn{
  border-radius: 36px !important;
}
.boxshowsignup{
  background: linear-gradient(270deg, #d9e6ff, white);
}
.min-height-200{
  min-height: 200px;
}
.persixmon{
  font-size: 14px !important;
  line-height: 1;
  font-weight: 600 !important;
}
.butpaynow{
  width: 100px !important;
  padding: 5px !important;
  /* text-align: center; */
  float: right;
}
.logoinsignup{
  width: 232px !important;
}
.glblclor{
  color: #2a58ae!important;
  font-weight: 700!important;
  font-size: 30px!important;
  vertical-align: middle;
  display: contents;
  font-weight: 700!important;
  line-height: 36px!important;
}
.brotop{
  width: 100%;
  border-top: 1px solid #d2d2d2;
  margin-top: 20px !important;
}
.prvivcy ul{
  padding: 0px;
  margin: 0px;
}
.brdinpi{
  border-top: 1px solid #d7d7d7;
  width: 98%;
  margin: 0px auto;
  position: relative;
  bottom: 18px;
}
.paywid{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-top: 12px;
  line-height: normal;
}
.orgpp{
  color: #F60;
}
.ppmds{
  color: #838383;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.byconf{
  color: #4D4D4D;
  padding: 15px 0px;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.cardinfoma{
  color: #000;
font-size: 13px;
font-style: normal;
position: relative;
top: 14px;
font-weight: 600;
line-height: normal;
}
.pwerbystr{
  color: #000;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}
.prvivcy ul li{
  list-style-type: circle;
  float: left;
  padding: 8px 41px 0px 0px;
}
.prvivcy ul li a{
  color: #A6A6A6;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.glbtext32{
  color: #000;
  text-align: center;
  font-size: 16px;
  width: 90%;
  font-style: normal;
  font-weight: 600;
  border-bottom: 1px solid #92ECC1;
  line-height: 28px;
  margin: 0px auto;
}
.btnwidthwe{
  width: 160px !important;
  text-align: right !important;
}
.dollerbnum{
  color: #808080;
text-align: center;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.bgcolorchangegr .glbtext, .bgcolorchangegr .dollerbnum{
  color:  #000 !important;
}
.checkstripeours{
  width: 100%;
}
.rpsebutt{
  color: #FFF;
  width: 116px;
  height: 25px;
  background-color: #FF9F9F;
text-align: center;
font-family: Nunito Sans;
font-size: 13px;
font-style: normal;
padding: 5px 6px;
border-radius: 5px;
font-weight: 600;

line-height: normal;
}
.heifht171{
height: 108px;
}
.heifht171 .anticon {
  padding-top:32px;
}
.cardbgshee{
  font-size: 15px!important;
  line-height: 21px!important;
  color: #909090!important;
  border: 1px solid #ebebeb!important;
  text-align: center;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  width: 100%!important;
}
.titlenewf{
  color: #313131;
font-family: Nunito Sans;
font-size: 17px;
font-style: normal;
font-weight: 800;
line-height: 25px;
}
.titlenewfView{
  display: flex;
  justify-content: space-between;

  .titleOptions{
    cursor: pointer;
  }
}
.titlenewfsublabe{
  color: #6A6A6A;
font-family: Nunito Sans;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.titlenewfsublabevie{
  color: #000;
  font-family: Nunito Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.boxterms{
  color: #4D4D4D;
font-size: 13px;
font-style: normal;
font-weight: 600;
position: relative;
bottom: 10px;
    line-height: normal;
    border-radius: 5px;
    border: 1px solid #F4F4F4;
    width: 94%;
    margin: 0px 0px 0px auto;
    padding: 5px 5px 5px 5px;
    background: #FBFBFB;
    width: 307px;
}
.pboxeds{
  color: #838383;
  font-size: 11px;
font-style: normal;
font-weight: 600;
}
.btn-pay{
  background-color: #2a58ae!important;
  border-color: #2a58ae!important;
  background:#2a58ae!important;
  width: 300px!important;
  padding: 7px 3px !important;
  font-size: 15px !important;
  margin: 0px auto;
  border-radius: 8px!important;
  font-weight: 400 !important;
  white-space: nowrap;
  text-align: center !important;
  font-family: Nunito Sans,sans-serif !important;
}
.ElementsApp, .ElementsApp .InputElement {
  font-size: 14px;
  line-height: 1.71428571;
  background-color: #fff !important;
  background-image: none;
  border: 1px solid #d9d9d9 !important;
}
.countdown {
  right: 6px;
  position: absolute;
  width: 40px;
  height: 40px;
  transform-style: preserve-3d;
  perspective: 1000px;
  background-color: #000;
  top: 5px;
  border-radius: 36px;

}

.countdown:before {
  content: '';
  left: 0;
  width: 100%;
  height: 10px;
  border-radius: 50%;
  filter: blur(14px);
  animation: shadow 1s linear infinite;
}
.countmentimer{
  background-color: #f60;
  top: 5px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 33px;
  color: white;
  right: 6px;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  border-radius: 36px;
}
@keyframes shadow {
  0%, 100% {
    transform: scale(0.5);
  }
  
  50% {
    transform: scale(1);
  }
}

.countdown .number {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(270deg);
  animation: animate 10s linear infinite;
}

.countdown .number:nth-child(1) {
  animation-delay: 0s;
}

.countdown .number:nth-child(2) {
  animation-delay: 1s;
}

.countdown .number:nth-child(3) {
  animation-delay: 2s;
}

.countdown .number:nth-child(4) {
  animation-delay: 3s;
}

.countdown .number:nth-child(5) {
  animation-delay: 4s;
}

.countdown .number:nth-child(6) {
  animation-delay: 5s;
}



.countdown .number h2 {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: #FFF;
}

@keyframes animate {
  0% {
    transform: rotateY(90deg);
  }
  
  10%, 100% {
    transform: rotateY(-90deg);
  }
}
.bgcolorchangegr{
  background: #A1FFAB;
 cursor: pointer;
    height: 130px;
    flex-shrink: 0;
    padding-top: 12px;
    border-radius: 6px;
    text-align: center;
}
.colorblckms{
  color: #5a5a5a;
}
.sunrel{
  color: #b1b1b1;
  text-align: center;
  font-size: 15px;
  padding: 16px 0px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.sunrelspa {
color: #2a58ae;
}
.pp-one{
  color: #000;
  text-align: center;
  font-size: 12px;
  margin: 5px 0px;
  font-style: normal;
  font-weight: 600;
  height: 33px;
  line-height: normal;
}
.pp-balance{
  color: #F60;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 37px;
}
.pp-popur{
  color: #A0A0A0;
text-align: center;
font-size: 13px;
font-style: normal;
background-color: #FFF;
border: 1px solid #e3e3e3;
font-weight: 600;
line-height: normal;
padding: 5px;
display: block;
margin: 7px auto;
width: 91%;
}
.glbtext{
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-top: 10px;
}
.min-height-220s {
  padding: 0px;
  margin: 0px;
  text-align: left;
  width: 98%;
  margin: 0px auto;
  height: 150px;
}
.min-height-220s li{
font-size: 11px;
text-decoration: none;
font-weight: 600;
text-align: left;
line-height: 25px;
list-style-type: none;
}
.videoflash-sm1:hover{
  color: #000;
}
.boxersbler{
  border-radius: 6px;
  border: 1px solid #E2E2E2;
  background: #FAFAFA;
  flex-shrink: 0;
  padding-top: 12px;
  height: auto;
  text-align: center;
  margin-bottom: 10px;
}
.colorblues{
  color: #2a58ae !important;
}
.glblackclor{
  color: #2a58ae !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  /* padding-left: 11px; */
  line-height: 28px !important;
  border-bottom: 2px solid #2a58ae;
  position: relative;
  left: 10px;
}
.glbleadkclor{
  color: #5a5a5a !important;
  font-weight: 500 !important;
  font-size: 21px !important;
  padding-top: 21px;
  /* padding-left: 11px; */
  line-height: 28px !important;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 7px;
  width: 356px;
  margin: 0px auto;

}
.callyellow{  background-color: #ffffd1;
  border-left:  5px solid#ffff00;
  font-size: 13px !important;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 0.2rem;
}
.minheigh232 {
  min-height: 320px;
}
.learning {
  font-weight: 500;
}
.scholdhead {
  font-size: 16px;
  font-weight: 700;
  color: #3a3a3a;
  line-height: 20px;
}
.brdradpad .ant-card-body {
  padding: 3px!important;
}
.schodpdf {
  color: #2a58ae;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.submreggm .ant-tabs .ant-tabs-bar{
  margin-bottom: 0px !important;
}
.bordevideo iframe{
  width: 100% !important;
}
.tilebust{
  color: #7b86bd !important;
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    display: block;
    width: 100%;
    margin-bottom: 5px;
}
.dis-none{
  display: none !important;
}
.timersteprighall{
  position: fixed;
  right: 21px;
  border-left: 5px solid #2aaeae !important;
  min-width: 250px;
  max-width: max-content;
  width: auto;
  top: 62px;
  font-size: 13px;
  line-height: 22px;
  font-weight: bolder;
  color: #000000d9;
  border: 1px solid #78f8fa;
  background-color: #e6fffe;
  text-align: center;
  border-radius: 2px 6px 6px 2px;
  padding: 4px 0px;
  z-index:5
}
.fontblosa{
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
    color: #000000d9;
}
.boxblack{
  background-color: #4c4c4c;
  border-radius: 4px;
  padding: 3px;
  color: #FFF;
  font-size: 13px;
  margin: 0px 3px 0px 0px;
  min-width: 25px;
  display: inline-block;
  font-weight: 500;
}
.form-card-page{
  height: 100vh;
    overflow-y: scroll;
    border-radius: 0;
}
.cardiconimage{
  width: 100%;
  padding: 20px 0px 10px;
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
}
.cardiconimage img{
  max-width: 456px;
  margin: 0px auto;
}
.blemrg{
  letter-spacing: normal;
  font-size: 14px;
  font-weight: 700;
  color: #282828de;
  text-decoration: underline;
}
.logoinsignup img{
  width: 100%;
}
.pricing-table-v1 .pricing-price{
  font-size: 26px !important;
}
.min-height-200 li{
  font-size: 14px !important;
}
.scrollfitpaid{
  height: 100vh;
    overflow-y: scroll;
}
.freespan{
  letter-spacing: normal;
  margin-left: 0.6em;
  font-size: .75rem;
  font-weight: 400;
  color: rgba(0,0,0,.87);
}
.btnwidth {
  width: 160px !important;
  margin: 0px auto;
}
.frepaid{
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  color: #1890ff;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.headersignu{
  background: #2A58AE;
  border-radius: 0px 0px 100px 100px;
  color: #FFF;
  padding: 12px 0px;
  font-size: 16px;
}
.signupimg{
  width: 188px;
  margin: 24px 6px;
}
.bgbluemn{
  background-color: #dbe9ff;
  padding: 3px;
}
.videoif{
  margin: 0px auto;
    /* border: 1px solid #21AC56; */
    text-align: center;
    height: 220px;
    width: 450px;
    box-shadow: 0px 0px 5px #6d82ffa3;
}
@-moz-document url-prefix() {
  .dottedlinks{
      margin-right:18px !important;
  }
}
.dottedlinks{
  width: 12px;
  height: 20px;
  background-color: #000;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-right: 4px;
  display: block;
  margin-left: 10px;
  cursor: pointer;
}
.ant-tooltip{
  max-width: 286px !important;
}
.dottedlinks:hover{
background-color: #2a58ae;
}
.posdotleft{
  position: fixed;
    left: 0px;
    top: 99px;
    z-index: 55;
    max-height: 480px;
    overflow-x: auto;
}
.posdotleft::-webkit-scrollbar {
  width: 5px !important;
}
.signupimg img{
  width: 100%;
}
.learningft span{
  color: #000;
}
div{
  font-size: 14px;
  font-weight: 500;
}
p{
  margin-bottom: 5px !important;
}
.learninbuild{
  font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #000000;
}
.learningft{
  font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 55px;
color: #2A58AE;
}
.submreggm .ant-tabs .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab{
  margin: 0 0px 0 0 !important;
  font-weight: 600 !important;
}
.submreggm .ant-tabs .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar{
  background-color: #209974 !important;
}
.submreggm .ant-tabs .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active{
  margin: 0 0px 0 0 !important;
  background-color: #2AAE86 !important;
  font-weight: 600 !important;
  color: #FFF !important;
}
.carbsign{
  background: #FFFFFF;
// box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.14);
// border-radius: 8px;
}
.dashboard-title {
  font-size: 16px;
  font-weight: normal;
  color: #131D40;
  text-align: center;
}
.ant-form-item.myb-0{
  margin-bottom: 0px !important;
}
.txtleft{
  text-align: left !important;
}
.ant-btn:hover, .ant-btn:focus {
  border-color: #ffffff7a !important;
}
.ant-menu-inline .ant-menu-item{
  margin-top: 0px !important;
}
.logosec{
  z-index: 574;
  position: relative;
  width: 164px;
  top: 5px;
}
.logimg{
  height: auto !important;
  width: 160px !important;
  z-index: 574;
  position: relative;
}
.logforpng{
  width: 232px !important;
  margin: 0px auto;
  display: flex;
}
.ant-btn:hover{  background-color: #464a58;
color: #FFF;
}
.ant-checkbox-checked::after{
  border: 1px solid #2a58ae;
}
.ant-btn-primary:hover, .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
  background-color: #5f81c2  !important;
  border-color: #5f81c2  !important;
  border-radius: 36px !important;
}
.mrb-50{
  margin-bottom: 50px !important;
}
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 0px;
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dae7fe;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a58ae;
}
.mobileview {
  display: none;
}
@media screen and (max-width:1366px){
  .posficesidenav{
    left: 13%;
  }
}
  
@media screen and (max-width:1270px){
  .posficesidenav{
    width: 920px;
  }
}
@media screen and (max-width: 1205px){
  .widthauro .recharts-wrapper{
  width: 100% !important;
  height: auto !important;
  }
  .widthauro .recharts-wrapper .recharts-surface{
    width: inherit !important;
    height: 100%;
    }
    .blueboxtoper{
      width: 56%;
  }
  .whitemiddle {
      width: 84%;
      top: 15px;
      left: 16px;
  }
  .greyboxtoper{
      width: 56%;
      left: 139px;
      top: 117px;
  }
    .recharts-legend-wrapper{
      position: relative !important;
      width: 100% !important;
      height: auto !important;
      left: 0px !important;
      bottom: 0px !important;
    }
    .posficesidenav {
      width: 774px;
      padding: 0px 0px;
      left:15%;
  }
  .menutext {
    font-size: 10px !important;
  }
  }


  @media screen and (max-width:1080px){
   
  .menutext {
    font-size: 13px !important;
  padding: 0px;
  }
  .header-right{
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .whitetext{
    font-size: 13px;
  }
  .mobileview {
    display: block;
  }
  .signeinasblk{
    display: block;
  }
  .posficesidenav{
    display: none;
  }
  .logo-mobile{
    display: block;
  }
  .whitetext{
    color: #fff  !important;
  }
  .ant-popover-inner-content{
    display: flex;
  }
  .roundcir{
    display: none !important;
  }
  .menutoggle{ display: block !important;
  color: #464A58 !important;
  font-size: 29px !important;
  padding: 16px 0px;
  }
  .nav-info{
    padding-left: 3px;
  }
  .more_courses { font-size: 10px !important;}
  .enroll_more {
    margin-top: 0px !important;
    margin-bottom: 10px;
}
.menutext{
  font-size: 12px !important;
  font-weight: lighter !important;
  text-align: left !important;
  font-family: 'Nunito Sans', sans-serif !important;
  text-transform: initial !important;
}

.imgicr {
  display: inline-block;
  width: 17px;
  margin: 0px 6px 0px 0px;
}
.iconbell{
  padding: 0px !important;
}
.menuitem { border-bottom: none;}
.app-header-inner .header-right {
  padding-right: 0px;
}
.list-inline-item{
  vertical-align: top;
}
.signed{
  display: none;
}
.menudrop {
  background-color: #464a58;
  border-radius: 0px;
}
.ant-dropdown-menu-item{
  color:#ffffff;
  font-weight: lighter;
  font-size: 14px;
}
.ant-dropdown-menu-item > a
{
  color: #FFF;
}
  }
  @media screen and (max-width:900px){
    .posficesidenav {
      left: 19%;
      // width: 63%;
  }
  .menutext {
    font-size: 11px !important;
  }
  .bgcolorcli ul li {
    width: 20% !important;
}
.bgcolorcli {
  background-color: #0000 !important;
}
.bgcolorcli ul {
  background: #fff0 !important;
}
  }

@media screen and (max-width: 840px){

}
@media screen and (max-width: 390px) {
  .mrdpl {
    display: inline-block;
    min-width: 150px;
    margin-left: 0px !important;
  }
}
.spanicomplus i {
  color: #FFF;
}
.whitemiddle{
  width: 313px;
  padding: 12px 15px;
  background-color: #FFF;
  box-shadow: 0px 0px 10px #b1b1b1;
  position: relative;
  top: 26px;
  left: 26px;
  z-index: 5;

}
.writeentex{
  font-size: 13px;
  line-height: 19px;
  color: #A0A0A0;
  padding-bottom: 10px;
}
.cardsubtext{
  font-size: 12px;
  font-weight: normal;
  color: #FFF;
  line-height: 18px;
  display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.relcourse{
  height: 200px;
  background-color: #FC6481;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  position: relative;
}
.rellocourse{
  height: 200px;
  background-color: #9085DA;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  position: relative;
}
.listaval{
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  color: #000;
}
.viemorelist{
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  color: #FFF;
  text-decoration: underline;
  position: absolute;
  bottom: 6px;

}
.viemorelist2{
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  color: #FFF;
  text-decoration: underline;

}
.pscourse{
  height: 200px;
  background-color: #38D996;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  position: relative;
}
.activityquiz{
  height: 200px;
  background-color: #4FADFF;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  position: relative;
}
.headrelct{
  font-size: 15px;
  font-weight: 700;
    color: #FFF;
    margin-bottom: 0px;
    margin-top: 9px;
}
.spanicomplus {
  color: #FFF;
  position: absolute;
  right: 4px;
  top: 0px;
  font-size: 18px;
  font-weight: bold;
}
.headerrel{
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0px;
  color: #525252;
}
.headerrel2{
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0px 3px;
  color: #525252;
  margin: 0px;
}
.blueboxtoper{
  background-color: #90CBD5;
  position: absolute;
  width: 208px;
  height: 86px;
}
.greyboxtoper{
  background-color: #CACACA;
  width: 208px;
  position: absolute;
  height: 86px;
  left: 158px;
  top: 136px;
  z-index: 4;
}
.imageicomn{
  background-color: #FFF;
  height: 40px;
  width: 40px;
  text-transform: uppercase;
  border-radius: 36px;
  text-align: center;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 41px;

}
.coursnamenedash{
  font-size: 15px;
  font-weight: 600;
  color: #FFF;
  text-align: left;
}
.coursnamenedash .anticon{
  color: #FFF !important;
    float: right;
    position: relative;
    right: 5px;
}
.coursdesnew{
  line-height: 18px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #6F6F6F;
}
.questioncourntnew{
  line-height: 18px;
    padding: 0px 5px 5px 5px;
    font-size: 12px;
    font-weight: 600;
    color: #6F6F6F;
}
.buttonenroll{
  background-color: #38D996;
  border-radius: 5px;
  padding: 5px;
  color: #FFF;

}
.indisbox{
  // border: 1px solid #CECECE;
  min-height: 156px;
  border-radius: 8px;
}
.boxbggraden{
  background-image: linear-gradient(to right, #9085DA, #90CBD5);
  border-radius: 4px 4px 0px 0px;
  padding: 5px 0px;
}
.cardcol-4{
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0px 8px 0px 0px;
}
.buttonenroll:hover{
  color: #FFF;
}
.buttonunenroll:hover{
  color: #000;
}
.buttonunenroll{
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 5px;
  color: #000;
}
.modelwidthscren .ant-modal-content .ant-modal-body{
  padding: 0px !important;
}
.bgroundwhite{
  background-color: #ffffffd1;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
}
.bgroundwhite .desc{
  font-size: 13px;
  color: #000;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.bgroundwhite .list{
  font-size: 13px;
  color: #000;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}
.bgfloares{
  height: 300px;
    background-position: center;
    background-size: contain;
    margin-bottom: 30px;
}
.wel-container{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px !important;
}
.card-heading{
  font-size: 14px !important;
  margin-bottom: 4px !important;
  color: #000;
  margin-top: 8px !important;
  font-weight: 700;
}
.floarimage{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  cursor: pointer;
    padding: 20px 10px;
    border-radius: 8px;
    color: #464a58;
    border: 1px solid #cbcbcb;
}
.corse-select-cont, .reel-course-container, .reel-lo-course-container{
  padding: 15px !important;
}
.corse-select-cont .card-grid{
  grid-gap: 17px 10px !important;
}
.action-buttons{
  padding: 15px !important;
}
.walk-thr-head{
  color: #000;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}
.walk-thr-sub-head{
  color: #438B39 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
.card-heading2 {
  font-size: 14px !important;
  margin-bottom: 4px !important;
  color: #fff;
  margin-top: 8px !important;
  font-weight: 700;
  text-align: left;
}
.card-description2{
  color: yellow;
  text-align: left;
  width: 178px;
}
.corse-select-cont .card-grid .card .card-heading {
  font-size: 14px !important;
  margin-bottom: 4px !important;
  color: #000;
  margin-top: 8px !important;
  font-weight: 700;
}
.corse-select-cont .card-grid .card{
  color: #3c3c3c;
  font-size: 10px;
  border: 1px solid #cbcbcb !important;
}
.corse-select-cont .card-grid .card .card-description {
  font-size: 13px !important;
  line-height: 18px !important;
}
.matchperdisk{
  background: rgb(200,234,255);
  padding: 8px !important;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
    margin:0px auto 5px auto !important;
background: linear-gradient(169deg, rgba(200,234,255,1) 0%, rgba(255,235,245,1) 35%, rgba(230,255,240,1) 100%);
}
.matchquetsmark{
  padding: 3px 7px !important;
  margin-bottom: 2px;
  position: relative;
  border: 1px solid #dddddd;
  border-right: 2px solid #2a58ae;
  background-color: #fbfbfb;
  /* right: 22px; */
  min-height: 35px;
  font-size: 14px !important;
  /* height: auto; */
  font-size: 14px !important;
  width: 100%;
  line-height: 20px;
}
.heaserms {
  color: #b3b3b3 !important;
  font-size: 13px !important;
  font-weight: normal;
  position: relative;
}
.matchquetsmarkanswerp p{
  padding: 3px 7px !important;
  margin-bottom: 2px;
  position: relative;
  border: 1px solid #dddddd;
  border-left: 5px solid #2a58ae;
  background-color: #fbfbfb;
  /* right: 22px; */
  min-height: 35px;
  font-size: 14px !important;
  /* height: auto; */
  font-size: 14px !important;
  width: 100%;
  line-height: 20px;
}
.bwallet{
  font-size: 13px;
  border: 1px solid #ffc9c9;
  padding: 5px 10px;
  background-color: #FFF;
  border-right: none;
}
.bwallet-active{
  font-size: 13px;
  border: 1px solid #76ef6e !important;
  padding: 5px 10px;
  border-right: none;
  background-color: #5dd755;
  color: #FFF;
}
.boxwallet .bwallet:last-child{
  border-right: 1px solid #ffc9c9;
}
.boxwallet{
  margin-right: 10px;
}
.pricerelwal{
  font-size: 14px;
  color: #000;
  font-weight: 600;
  background-color: #FFF;
  border: 1px solid #c3c3c3;
  padding: 5px;
  margin-right: 8px;
  border-radius: 5px;
}
.pricerel{
  color: #2a58ae;
  font-weight: 800;
}
@media screen and (max-width: 1205px){

    .blueboxtoper{
      width: 56%;
  }
  .whitemiddle {
      width: 84%;
      top: 15px;
      left: 16px;
  }
  .greyboxtoper{
      width: 56%;
      left: 130px;
      top: 134px;
  }

}