
.app-header {
  // badge
  .ant-badge { 
    sup {
      box-shadow: none;
    }
  }

  .bg-dark,
  .bg-primary,
  .bg-success,
  .bg-info,
  .bg-danger {
    color: $white;
    a {
      color: $white;
    }

    // badge
    .ant-badge { 
      i {
        color: $white;
      }
    }
    
    // search box
    .search-box {
      ::placeholder {
        color: rgba($white, .65);
      }
      .input-bar:before,
      .input-bar:after {
        background: $white; 
      }
    }
  }

  .bg-white,
  .bg-warning {
    color: $body-color2;
    a {
      color: $body-color2;
    }

  }
}
.header-pos {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
}
.hiderhead{
  opacity: 0;
  -webkit-transition: all ease-out .5s;
  -moz-transition: all ease-out .5s;
  -o-transition: all ease-out .5s;
  transition: all ease-out .5s;
}
.hiderhead:hover{
  opacity: 1;
  -webkit-transition: all ease-out .5s;
  -moz-transition: all ease-out .5s;
  -o-transition: all ease-out .5s;
  transition: all ease-out .5s;
}